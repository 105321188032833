const load = 'app/visitors/LOAD';
const loadSuccess = 'app/visitors/LOADSUCCESS';
const loadFailure = 'app/visitors/LOADFAILURE';
const loadMore = 'app/visitors/LOADMORE';
const loadMoreSuccess = 'app/visitors/LOADMORESUCCESS';
const loadMoreFailure = 'app/visitors/LOADMOREFAILURE';
const unlock = 'app/visitors/UNLOCK';
const unlockSuccess = 'app/visitors/UNLOCKSUCCESS';
const unlockFailure = 'app/visitors/UNLOCKFAILURE';
const setLastLoadTime = 'app/visitors/lastLoad/SET';
const addNewVisitor = 'app/visitors/ADDNEWVISITOR';

export default {
  load,
  loadSuccess,
  loadFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  unlock,
  unlockSuccess,
  unlockFailure,
  setLastLoadTime,
  addNewVisitor,
};
