const friendShipStatus = {
  noRequest: 'NO_REQUEST',
  pending: 'PENDING',
  accepted: 'ACCEPTED',
  denied: 'DENIED',
  blocked: 'BLOCKED',
};

const matchingStatus = {
  matched: 'MATCHED',
  liked: 'LIKED',
  noRequest: 'NO_REQUEST',
};

export { friendShipStatus, matchingStatus };
