import { differenceWith, pick, keyBy, uniq } from 'lodash';
import { combineReducers } from 'redux';
import conversationMessagesTypes from '../conversationMessages/types';
import types from './types';

const reducer = (state = { byId: {}, allIds: [] }, action) => {
  const { type } = action;
  switch (type) {
    case types.deleteMessages: {
      const { conversationId } = action;
      const { byId, allIds } = state;
      const remainingIds = allIds.filter(item => byId[item].conversationId !== conversationId);
      return { allIds: remainingIds, byId: pick(byId, remainingIds) };
    }
    case conversationMessagesTypes: {
      const { id } = action;
      const { byId, allIds } = state;
      const remainingIds = allIds.filter(item => byId[item].conversationId !== id);
      return { allIds: remainingIds, byId: pick(byId, remainingIds) };
    }
    case conversationMessagesTypes.resetConversations: {
      const { byId, allIds } = state;
      const remainingIds = differenceWith(
        allIds,
        action.ids,
        (id, conId) => byId[id].conversationId === conId
      );
      return { allIds: remainingIds, byId: pick(byId, remainingIds) };
    }
    case types.fetchMessagesSuccess: {
      const { messages } = action;
      const { allIds, byId } = state;
      return {
        byId: {
          ...byId,
          ...keyBy(messages, 'id'),
        },
        allIds: uniq([...allIds, ...messages.map(({ id }) => id)]),
      };
    }
    case types.sendIcebreakerSuccess:
    case types.sendMessageSuccess:
    case types.receiveMessage: {
      const { message } = action;
      const { id } = message;
      const { allIds, byId } = state;
      return { allIds: uniq([...allIds, id]), byId: { ...byId, [id]: message } };
    }
    case types.addMessages: {
      const { messages } = action;
      const { byId, allIds } = state;
      return {
        byId: { ...byId, ...keyBy(messages, 'id') },
        allIds: uniq([...allIds, ...messages.map(({ id }) => id)]),
      };
    }
    default:
      return state;
  }
};

const sendingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.sendIcebreaker:
    case types.sendMessage:
      return true;
    case types.sendIcebreakerSuccess:
    case types.sendIcebreakerFailure:
    case types.sendMessageSuccess:
    case types.sendMessageFailure:
      return false;
    default:
      return state;
  }
};

const sendingIcebreakerReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.sendIcebreaker:
      return true;
    case types.sendIcebreakerSuccess:
    case types.sendIcebreakerFailure:
      return false;
    default:
      return state;
  }
};



export default combineReducers({ messages: reducer, isSending: sendingReducer, iceBreakerSending: sendingIcebreakerReducer });
