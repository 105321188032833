import types from './types';

const getConversations = () => ({ type: types.getConversations });
const getConversationsSuccess = (conversations, moreRecords, nextCursor) => ({
  type: types.getConversationsSuccess,
  conversations,
  moreRecords,
  nextCursor,
});
const getConversation = (id) => ({ type: types.getConversation, id });
const fetchFailure = (error) => ({ type: types.fetchFailure, error });

const getMoreConversations = (nextCursor) => ({ type: types.getMoreConversations, nextCursor });
const getMoreConversationsSuccess = (conversations, moreRecords, nextCursor) => ({
  type: types.getMoreConversationsSuccess,
  moreRecords,
  conversations,
  nextCursor,
});
const getMoreConversationsFailure = (error) => ({
  type: types.getMoreConversationsFailure,
  error,
});
const addConversation = (conversation) => ({ type: types.addConversation, conversation });

const getUnread = () => ({ type: types.getUnread });
const fetchUnreadSuccess = (unread, matches) => ({
  type: types.fetchUnreadSuccess,
  unread,
  matches,
});
const removeUnread = (id) => ({ type: types.removeUnread, id });
const markAsRead = (id) => ({ type: types.markAsRead, id });
const getConversationSuccess = (conversation) => ({
  type: types.getConversationSuccess,
  conversation,
  id: conversation.id,
});
const getConversationFailure = (error) => ({ type: types.getConversationFailure, error });

const closeQuickView = (id) => ({ type: types.closeQuickView, id });

const getFavoriteConversations = () => ({ type: types.getFavoriteConversations });
const getFavoriteConversationsSuccess = (conversations, moreRecords) => ({
  type: types.getFavoriteConversationsSuccess,
  moreRecords,
  conversations,
});
const getFavoriteConversationsFailure = (error) => ({
  type: types.getFavoriteConversationsFailure,
  error,
});

const getMoreFavorites = (page) => ({ type: types.getMoreFavorites, page });
const getMoreFavoritesSuccess = (conversations, moreRecords) => ({
  type: types.getMoreFavoritesSuccess,
  conversations,
  moreRecords,
});
const getMoreFavoritesFailure = (error) => ({ type: types.getMoreFavoritesFailure, error });

const favoriteConversation = (id, userId, favorite) => ({
  type: types.favoriteConversation,
  id,
  userId,
  favorite,
});
const favoriteConversationSuccess = (id, favorite) => ({
  type: types.favoriteConversationSuccess,
  id,
  favorite,
});
const favoriteConversationFailure = (error) => ({ type: types.favoriteConversationFailure, error });
const getStarProducts = () => ({ type: types.getStarProducts });
const getStarProductsSuccess = (products) => ({ type: types.getStarProductsSuccess, products });
const getStarProductsFailure = (error) => ({ type: types.getStarProductsFailure, error });
const buyStarPackage = (id, price, count) => ({ type: types.buyStarPackage, id, price, count });
const buyStarPackageSuccess = (count) => ({ type: types.buyStarPackageSuccess, count });
const buyStarPackageFailure = (error) => ({ type: types.buyStarPackageFailure, error });
const sentIcebreaker = (userId) => ({
  type: types.sentIcebreaker,
  userId,
});

export default {
  getConversations,
  getConversationsSuccess,
  fetchFailure,
  getMoreConversations,
  getMoreConversationsSuccess,
  getMoreConversationsFailure,
  addConversation,
  getUnread,
  fetchUnreadSuccess,
  removeUnread,
  markAsRead,
  getConversationSuccess,
  getConversationFailure,
  getConversation,
  closeQuickView,
  favoriteConversation,
  favoriteConversationSuccess,
  favoriteConversationFailure,
  sentIcebreaker,
  getStarProducts,
  getStarProductsSuccess,
  getStarProductsFailure,
  buyStarPackage,
  buyStarPackageSuccess,
  buyStarPackageFailure,
  getMoreFavorites,
  getMoreFavoritesSuccess,
  getMoreFavoritesFailure,
  getFavoriteConversations,
  getFavoriteConversationsSuccess,
  getFavoriteConversationsFailure,
};
