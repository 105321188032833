import {
  call, put, takeLatest, take,
} from 'redux-saga/effects';
import types from './types';
import Config from '../config';
import { GetProfile, ReportUser } from '../services/webAppEndPoint';
import actions from './actions';
import Tracking from '../tracking';

function* getProfile({ id }) {
  try {
    const profile = yield call(GetProfile, id);
    yield put(actions.getProfileSuccess(profile));
  } catch (error) {
    yield put(actions.getProfileFailure(id, error));
  }
}

function* handleGetProfiles() {
  yield takeLatest(types.getProfile, getProfile);
}

function* removeLastRecievedProfile() {
  const ids = [];
  while (true) {
    const action = yield take(types.getProfileSuccess);
    if (action.profile && action.profile.id && ids.every(item => item !== action.profile.id)) {
      ids.push(action.profile.id);
    }
    if (ids.length > Config.cacheAmount) {
      const id = ids.shift();
      yield put(actions.removeProfile(id));
    }
  }
}

function* reportProfile({
  blocked,
  fake_profile,
  ad,
  wrong_gender,
  reported_guid,
  violation,
  guideline,
  freetext
}) {
  try {
    yield call(ReportUser, {
      blocked,
      fake_profile,
      ad,
      wrong_gender,
      reported_guid,
      violation,
      guideline,
      freetext
    });
    yield put(actions.reportProfileSuccess());
    Tracking.track('reportUser', {
      report_user_block: blocked,
      report_user_fake: fake_profile,
      report_user_ad: ad,
      report_user_insult: violation,
      report_user_wrong_gender: wrong_gender,
      report_user_guidlines: guideline,
    });
  } catch (error) {
    yield put(actions.reportProfileFailure());
  }
}

function* handleReportProfile() {
  yield takeLatest(types.reportProfile, reportProfile);
}

export default {
  handleGetProfiles,
  removeLastRecievedProfile,
  handleReportProfile,
};
