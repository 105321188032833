import { put, call, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetVisitors, UnlockVisitor } from '../services/webAppEndPoint';
import { paymentFunctions } from '../payment';
import Tracking from '../tracking';
import { takeUniq } from '../sagas';

function* loadVisitors() {
  try {
    const { visitors, moreItems, nextCursor } = yield call(GetVisitors);
    yield put(actions.loadSuccess(visitors, moreItems, nextCursor));
  } catch (error) {
    yield put(actions.loadFailure(error));
  }
}

function* loadMoreVisitors({ nextCursor }) {
  try {
    const { visitors, moreItems, nextCursor: nextCursorr } = yield call(GetVisitors, nextCursor);
    yield put(actions.loadMoreSuccess(visitors, moreItems, nextCursorr));
  } catch (error) {
    yield put(actions.loadMoreFailure(error));
  }
}

function* unlock({ id, value }) {
  try {
    const userInfo = yield call(UnlockVisitor, id);
    yield* paymentFunctions.finishTransaction(value);
    Tracking.track('visitorUnlock');
    yield put(actions.unlockSuccess(id, userInfo));
  } catch (error) {
    yield put(actions.unlockFailure(id, error));
  }
}

function* handleLoadVisitors() {
  yield takeLatest(types.load, loadVisitors);
}

function* handleLoadMore() {
  yield takeLatest(types.loadMore, loadMoreVisitors);
}

function* handleUnlockVisitors() {
  yield* takeUniq(types.unlock, unlock, ({ id }) => id);
}

export default {
  handleLoadVisitors,
  handleUnlockVisitors,
  handleLoadMore,
};
