import { formatISO, parseISO } from 'date-fns';
import types from './types';

const getPresents = id => ({ type: types.getPresents, id });
const getPresentsSuccess = (presents, users) => ({
  type: types.getPresentsSuccess,
  presents,
  users,
  timestamp: formatISO(new Date()),
});
const getPresentsFailure = error => ({ type: types.getPresentsFailure, error });
const getSentPresents = () => ({ type: types.getSentPresents });
const getSentPresentsSuccess = (presents, users) => ({
  type: types.getSentPresentsSuccess,
  presents,
  users,
  timestamp: formatISO(new Date()),
});
const getSentPresentsFailure = error => ({ type: types.getSentPresentsFailure, error });
const sendGift = (id, present, value) => ({
  type: types.sendGift,
  id,
  value,
  present,
});
const sendGiftSuccess = () => ({ type: types.sendGiftSuccess });
const sendGiftFailure = error => ({ type: types.sendGiftFailure, error });
const getGiftList = () => ({ type: types.getGiftList });
const getGiftListSuccess = list => ({ type: types.getGiftListSuccess, list });
const getGiftListFailure = error => ({ type: types.getGiftListFailure, error });
const getNewest = (id, timestamp) => ({ type: types.getNewestGifts, id, timestamp });
const getNewestSuccess = (presents, users) => ({
  type: types.getNewestGiftsSuccess,
  presents,
  users,
  timestamp: formatISO(new Date()),
});
const getNewestFailure = error => ({ type: types.getNewestGiftsFailure, error });

export default {
  getPresents,
  getPresentsSuccess,
  getPresentsFailure,
  getSentPresents,
  getSentPresentsSuccess,
  getSentPresentsFailure,
  sendGift,
  sendGiftSuccess,
  sendGiftFailure,
  getGiftList,
  getGiftListSuccess,
  getGiftListFailure,
  getNewest,
  getNewestSuccess,
  getNewestFailure,
};
