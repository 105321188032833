import { createSelector } from 'reselect';
import { sortBy, reverse, keyBy, groupBy, maxBy, mapValues } from 'lodash';

const getMessagesPart = state => state.messages;
const getMessagesById = state => getMessagesPart(state).messages.byId;
const getAllMessageIds = state => getMessagesPart(state).messages.allIds;
const getIsSendingIcebreaker = state => getMessagesPart(state).iceBreakerSending;

const getAllMessages = createSelector(getAllMessageIds, getMessagesById, (ids, items) =>
  ids.map(id => items[id])
);

const makeGetMessagesForConversation = (getConversationId, getParticipants) =>
  createSelector(
    getConversationId,
    getAllMessages,
    getParticipants,
    (id, messages, participants) => {
      const participantsDictionary = keyBy(participants, 'id');
      return reverse(
        sortBy(
          messages.filter(({ conversationId }) => conversationId === id),
          'createdAt'
        )
      ).map(({ id: messageId, userId, text, type, createdAt, message }) => ({
        text,
        type,
        _id: messageId.toString(),
        user: participantsDictionary[userId]
          ? {
            _id: userId,
            avatar: participantsDictionary[userId].image.uri,
            name: participantsDictionary[userId].name,
          }
          : { _id: userId },
        message,
        createdAt,
      }));
    }
  );

const getLatestMessagesForConversations = createSelector(getAllMessages, messages =>
  mapValues(groupBy(messages, 'conversationId'), conversationMessages =>
    maxBy(
      conversationMessages.filter(item => !!item),
      'createdAt'
    )
  )
);

export default {
  makeGetMessagesForConversation,
  getLatestMessagesForConversations,
  getIsSendingIcebreaker
};
