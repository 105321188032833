import types from './types';

const load = () => ({ type: types.load });
const loadSuccess = (visitors, moreItems, nextCursor) => ({
  type: types.loadSuccess,
  visitors,
  moreItems,
  nextCursor,
});
const loadFailure = (error) => ({ type: types.loadFailure, error });
const loadMore = (nextCursor) => ({ type: types.loadMore, nextCursor });
const loadMoreSuccess = (visitors, moreItems, nextCursor) => ({
  type: types.loadMoreSuccess,
  visitors,
  moreItems,
  nextCursor,
});
const loadMoreFailure = (error) => ({ type: types.loadMoreFailure, error });
const unlock = (id, value) => ({
  type: types.unlock,
  id,
  value,
});
const unlockSuccess = (id, userInfo) => ({ type: types.unlockSuccess, id, userInfo });
const unlockFailure = (id, error) => ({ type: types.unlockFailure, id, error });
const setLastLoadTime = (time) => ({ type: types.setLastLoadTime, time });
const addNewVisitor = () => ({ type: types.addNewVisitor });

export default {
  load,
  loadSuccess,
  loadFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  unlock,
  unlockSuccess,
  unlockFailure,
  setLastLoadTime,
  addNewVisitor,
};
