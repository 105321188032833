import uuid from "uuid/v4";
import types from './types';

const addNotification = (notificationType, data) => ({
  type: types.addNotification,
  notification: {
    id: uuid(),
    notificationType,
    data,
  }
});

export default {
  addNotification,
};
