import { createSelector, createStructuredSelector } from 'reselect';
import { reverse, sortBy, uniqBy } from 'lodash';
import { profileSelectors } from '../profile';
import { messagesSelectors } from '../messages';

const getConversationsPart = state => state.conversations;
const getConversationsCollection = state => getConversationsPart(state).conversations;
const getConversationsById = state => getConversationsCollection(state).byId;
const getAllConversationIds = state => getConversationsCollection(state).allIds;
const getNextPage = state => getConversationsPart(state).nextPage;
const getMoreRecords = state => getConversationsPart(state).moreRecords;
const getIsLoading = state => getConversationsPart(state).isFetching;
const getNewMatches = state => getConversationsPart(state).newMatches;
const getUnread = state => getConversationsPart(state).unread;
const getConversationId = (_state, { conversationId, convId }) => conversationId || convId;
const getParticipants = (_state, { participants, convUsers }) => participants || convUsers;
const getOnQuickView = state => getConversationsPart(state).onQuickView;
const getOpenStars = state => getConversationsPart(state).openStars;
const getProducts = state => getConversationsPart(state).products;
const getIsBuying = state => getConversationsPart(state).isBuying;
const getFavoriteIsLoading = state => getConversationsPart(state).favoriteIsLoading;
const getFavoriteMoreRecords = state => getConversationsPart(state).favoriteMoreRecords;
const getFavoriteNextPage = state => getConversationsPart(state).favoriteNextPage;

const hasConversation = id => state => !!getConversationsById(state)[id];

const getBuyStarPackages = createStructuredSelector({
  products: getProducts,
  isBuying: getIsBuying,
});

const getConversationById = createSelector(
  getConversationsById,
  getConversationId,
  (items, id) => items[id]
);

const makeGetConversationById = () =>
  createSelector(getConversationsById, getConversationId, (items, id) => items[id]);

const getAllConversations = createSelector(
  getConversationsById,
  getAllConversationIds,
  messagesSelectors.getLatestMessagesForConversations,
  (items, ids, messages) =>
    ids.reduce(
      (arr, id) => (messages[id] ? [...arr, { ...items[id], lastMessage: messages[id] }] : arr),
      []
    )
);

const getConversationByParticipant = createSelector(
  getAllConversations,
  getParticipants,
  (conversations, participants) =>
    participants.length > 0
      ? conversations.find(
        ({ users }) =>
          users &&
          participants.every(({ id: partId }) =>
            users.some(({ id: userId }) => partId === userId)
          )
      )
      : undefined
);

const makeGetSingleConversation = () =>
  createSelector(
    getConversationById,
    getConversationByParticipant,
    (idCon, partCon) => idCon || partCon
  );

const makeGetConversationId = getConversation =>
  createSelector(getConversation, con => con && con.id);

const makeGetIsUnread = getId =>
  createSelector(
    getId,
    getUnread,
    (id, unread) => !!unread.find(({ id: unreadId }) => unreadId === id)
  );

const makeGetConversationParticipants = getConversation =>
  createSelector(
    getConversation,
    getParticipants,
    profileSelectors.getProfile,
    (conversation, participants, userProfile) =>
      (conversation && conversation.users) ||
      (participants && uniqBy([...participants, userProfile], 'id')) ||
      []
  );

const makeGetOtherParticipant = getAllParticipants =>
  createSelector(getAllParticipants, profileSelectors.getProfileId, (participants, id) =>
    participants.find(({ id: userId }) => userId !== id)
  );

const getSortedConversations = createSelector(getAllConversations, items =>
  reverse(
    sortBy(
      items.reduce((arr, item) => (item.isNewMatch || item.isFavorite ? arr : [item, ...arr]), []),
      'lastMessage.createdAt'
    )
  )
);

const getSortedFavorites = createSelector(getAllConversations, items =>
  reverse(
    sortBy(
      items.reduce((arr, item) => (item.isNewMatch || !item.isFavorite ? arr : [item, ...arr]), []),
      'lastMessage.createdAt'
    )
  )
);

const getConversations = createStructuredSelector({
  conversations: getSortedConversations,
  nextPage: getNextPage,
  moreRecords: getMoreRecords,
  isFetching: getIsLoading,
  unread: getUnread,
});

const getFavorites = createStructuredSelector({
  favorites: getSortedFavorites,
  favoriteNextPage: getFavoriteNextPage,
  favoriteMoreRecords: getFavoriteMoreRecords,
  isFetching: getFavoriteIsLoading,
  favCounter: getUnread,
});

const getNewMatchCount = createSelector(getNewMatches, matches => matches.length);

const getUnreadCount = createSelector(getSortedConversations, getUnread, (conversations, unread) =>
  unread.filter(item => conversations.find(({ id: conversationId }) => conversationId === item.id))
);

const getFavUnreadCount = createSelector(getSortedFavorites, getUnread, (conversations, unread) =>
  unread.filter(item => conversations && conversations.find(({ id: conversationId }) => conversationId === item.id))
);

const getNewMessagesCount = createSelector(getUnread, unread => unread.length);

export default {
  getConversations,
  getNewMessagesCount,
  getNewMatchCount,
  getUnreadCount,
  getFavUnreadCount,
  makeGetSingleConversation,
  makeGetConversationParticipants,
  makeGetConversationId,
  makeGetIsUnread,
  makeGetOtherParticipant,
  hasConversation,
  getOnQuickView,
  makeGetConversationById,
  getConversationId,
  getOpenStars,
  getBuyStarPackages,
  getSortedFavorites,
  getFavorites,
  getIsBuying,
  getMoreRecords,
  getFavoriteMoreRecords,
  getNextPage,
  getFavoriteNextPage
};
