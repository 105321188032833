import { combineReducers } from 'redux';
import { uniq, omit } from 'lodash';
import types from './types';

const allIdsReducer = (state = [], action) => {
  switch (action.type) {
    case types.loadMoreConversationSuccess:
    case types.getConversationSuccess: {
      const { id } = action;
      return uniq([...state, id]);
    }
    case types.resetConversations:
    case types.deleteConversationSuccess: {
      const { id } = action;
      return state.filter((item) => item !== id);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getConversationSuccess: {
      const { id, moreRecords, nextCursor } = action;
      return { ...state, [id]: { moreRecords, nextPage: nextCursor } };
    }
    case types.loadMoreConversationSuccess: {
      const { id } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    case types.deleteConversationSuccess: {
      const { id } = action;
      return omit(state, id);
    }
    default:
      return state;
  }
};

const valueReducer = (state = {}, action) => {
  switch (action.type) {
    case types.loadMoreConversationSuccess: {
      const { moreRecords, nextCursor } = action;
      return { ...state, moreRecords, nextPage: nextCursor };
    }
    default:
      return state;
  }
};

const deletingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.deleteConversation:
      return true;
    case types.deleteConversationFailure:
    case types.deleteConversationSuccess:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  byId: byIdReducer,
  allIds: allIdsReducer,
  isDeleting: deletingReducer,
});
