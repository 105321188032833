import {omit} from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import { presentsTypes } from '../presents';
import { friendsTypes } from '../friends';
import { messagesTypes } from '../messages';
import { friendShipStatus } from '../enums';
import { swipesTypes } from '../swipes';

const reducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.getProfileSuccess: {
      const { id } = action.profile;
      return { ...state, [id]: action.profile };
    }
    case types.getProfileError: {
      const { id, error } = action;
      return { ...state, [id]: { error } };
    }
    case types.removeProfile: {
      const { id } = action;
      return omit(state, id);
    }
    case swipesTypes.swipeRight:
    case friendsTypes.rejectFriendSuccess:
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.sendFriendRequestSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
    case friendsTypes.addFriendSuccess:
    case presentsTypes.sendGiftSuccess: {
      const { id } = action;
      if (state[id]) {
        return { ...state, [id]: profileReducer(state[id], action) };
      }
      return state;
    }
    case messagesTypes.sendIcebreakerSuccess: {
      const { userId: id } = action;
      if (state[id]) {
        return { ...state, [id]: profileReducer(state[id], action) };
      }
      return state;
    }
    default:
      return state;
  }
};
const reportingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.reportProfile:
      return true;
    case types.reportProfileSuccess:
    case types.reportProfileFailure:
      return false;
    default:
      return state;
  }
};

const profileReducer = (state, action) => {
  const { type } = action;
  switch (type) {
    case presentsTypes.sendGiftSuccess: {
      const { presents } = state;
      return { ...state, presents: presents ? presents + 1 : 1 };
    }
    case friendsTypes.sendFriendRequestSuccess:
      return {
        ...state,
        friendRequestStatus: friendShipStatus.pending,
        friendshipCounter: state.friendshipCounter ? state.friendshipCounter + 1 : 1,
      };
    case friendsTypes.removeFriendSuccess:
    case friendsTypes.retreatFriendRequestSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.noRequest };
    case friendsTypes.addFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.accepted };
    case friendsTypes.rejectFriendSuccess:
      return { ...state, friendRequestStatus: friendShipStatus.denied };
    case messagesTypes.sendIcebreakerSuccess:
      return { ...state, sentIcebreaker: true };
    case swipesTypes.swipeRight:
      return{ ...state, hasLiked: true};
    default:
      return state;
  }
};

export default combineReducers({ list: reducer, isReporting: reportingReducer });
