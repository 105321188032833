const deleteMessages = 'app/messages/SETMATCHES';
const receiveMessage = 'app/messages/RECEIVEMESSAGE';
const sendMessage = 'app/messages/SENDMESSAGE';
const addMessages = 'app/messages/ADDMESSAGES';
const sendMessageSuccess = 'app/messages/SENDMESSAGESUCCESS';
const sendMessageFailure = 'app/messages/SENDMESSAGEFAILURE';
const sendIcebreaker = 'app/messages/SENDICEBREAKER';
const sendIcebreakerSuccess = 'app/messages/SENDICEBREAKERSUCCESS';
const sendIcebreakerFailure = 'app/messages/SENDICEBREAKERFAILURE';

export default {
  deleteMessages,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  receiveMessage,
  addMessages,
  sendIcebreaker,
  sendIcebreakerSuccess,
  sendIcebreakerFailure,
};
