const load = 'app/swipes/LOAD';
const loadSuccess = 'app/swipes/LOADSUCCESS';
const loadFailure = 'app/swipes/LOADFAILURE';
const clearSwipes = 'app/swipes/CLEAR';
const swipeRight = 'app/swipes/RIGHT';
const swipeLeft = 'app/swipes/LEFT';
const swipeFailure = 'app/swipes/SWIPEFAILURE';
const matched = 'app/swipes/MATCHED';
const matchFinished = 'app/swipes/MATCHFINISHED';

export default {
  load,
  loadSuccess,
  loadFailure,
  swipeLeft,
  swipeRight,
  swipeFailure,
  matched,
  matchFinished,
  clearSwipes,
};
