const getConversations = 'app/conversations/GETCONVERSATIONS';
// const getConversations = 'app/conversations/GETCONVERSATIONS?page=1&favorites=1';
const getConversationsSuccess = 'app/conversations/GETCONVERSATIONSSUCCESS';
const fetchFailure = 'app/conversations/FETCHFAILURE';
const getMoreConversations = 'app/conversations/GETMORECONVERSATIONS';
const getMoreConversationsSuccess = 'app/conversations/GETMORECONVERSATIONSSUCCESS';
const getMoreConversationsFailure = 'app/conversations/GETMORECONVERSATIONSFAILURE';
const addConversation = 'app/conversations/ADDCONVERSATION';
const getUnread = 'app/conversations/GETUNREAD';
const removeUnread = 'app/conversations/REMOVEUNREAD';
const fetchUnreadSuccess = 'app/conversations/FETCHUNREADSUCCESS';
const markAsRead = 'app/conversations/MARKASREAD';
const getConversation = 'app/conversations/GETCONVERSATION';
const getConversationSuccess = 'app/conversations/GETCONVERSATIONSUCCESS';
const getConversationFailure = 'app/conversations/GETCONVERSATIONSFAILURE';
const closeQuickView = 'app/conversations/CLOSEQUICKVIEW';
const getFavoriteConversations = 'app/conversations/GETFAVORITES';
const getFavoriteConversationsSuccess = 'app/conversations/GETFAVORITESSUCCESS';
const getFavoriteConversationsFailure = 'app/conversations/GETFAVORITESFAILURE';
const getMoreFavorites = 'app/conversations/GETMOREFAVORITES';
const getMoreFavoritesSuccess = 'app/conversations/GETMOREFAVORITESSUCCESS';
const getMoreFavoritesFailure = 'app/conversations/GETMOREFAVORITESFAILURE';
const favoriteConversation = 'app/conversations/FAVORITECONVERSATION';
const favoriteConversationSuccess = 'app/conversation/FAVORITECONVERSATIONSUCCESS';
const favoriteConversationFailure = 'app/conversation/FAVORITECONVERSATIONFAILURE';
const sentIcebreaker = 'app/conversation/SENTICEBREAKER';
const getStarProducts = 'app/conversation/GETSTARPRODUCTS';
const getStarProductsSuccess = 'app/conversation/GETSTARPRODUCTSSUCCESS';
const getStarProductsFailure = 'app/conversation/GETSTARPRODUCTSFAILURE';
const buyStarPackage = 'app/conversation/BUYSTARPACKAGE';
const buyStarPackageSuccess = 'app/conversation/BUYSTARPACKAGESUCCESS';
const buyStarPackageFailure = 'app/conversation/BUYSTARPACKAGEFAILURE';

export default {
  getConversations,
  fetchFailure,
  getMoreConversations,
  getMoreConversationsSuccess,
  getMoreConversationsFailure,
  addConversation,
  getUnread,
  removeUnread,
  getConversationsSuccess,
  fetchUnreadSuccess,
  markAsRead,
  getConversation,
  getConversationSuccess,
  getConversationFailure,
  closeQuickView,
  getFavoriteConversations,
  getFavoriteConversationsSuccess,
  getFavoriteConversationsFailure,
  getMoreFavorites,
  getMoreFavoritesSuccess,
  getMoreFavoritesFailure,
  getStarProducts,
  getStarProductsSuccess,
  getStarProductsFailure,
  buyStarPackage,
  buyStarPackageSuccess,
  buyStarPackageFailure,
  favoriteConversation,
  favoriteConversationSuccess,
  favoriteConversationFailure,
  sentIcebreaker
};
