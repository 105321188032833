const getConversation = 'app/conversationMessages/GET';
const getConversationSuccess = 'app/conversationMessages/GETSUCCESS';
const getConversationFailure = 'app/conversationMessages/GETFAILURE';
const loadMoreConversation = 'app/conversationMessages/LOADMORE';
const loadMoreConversationSuccess = 'app/conversationMessages/LOADMORESUCCESS';
const loadMoreConversationFailure = 'app/conversationMessages/LOADMOREFAILURE';
const deleteConversation = 'app/conversations/DELETECONVERSATION';
const deleteConversationSuccess = 'app/conversations/DELETECONVERSATIONSSUCCESS';
const deleteConversationFailure = 'app/conversations/DELETECONVERSATIONFAILURE';
const resetConversations = 'app/conversations/RESETMULTIPLE';

export default {
  getConversation,
  getConversationSuccess,
  getConversationFailure,
  loadMoreConversation,
  loadMoreConversationSuccess,
  loadMoreConversationFailure,
  deleteConversation,
  deleteConversationSuccess,
  deleteConversationFailure,
  resetConversations,
};
