import { put, call, takeLatest } from 'redux-saga/effects';
import { head } from 'lodash';
import actions from './actions';
import types from './types';
import { messagesActions } from '../messages';
import { GetConversationId, GetMessages, DeleteConversation } from '../services/webAppEndPoint';

const messageLimit = 15;

function* getConversation({ id, users }) {
  try {
    if (!id) {
      const conversationId = yield call(
        GetConversationId,
        users.map(({ id: userId }) => userId)
      );
      try {
        const { moreRecords, messages, nextCursor } = yield call(
          GetMessages,
          conversationId,
          messageLimit
        );
        yield put(
          actions.getConversationSuccess(conversationId, moreRecords, {
            id: conversationId,
            lastMessage: head(messages),
            users,
            isNewMatch: messages.length === 0,
            nextCursor,
          })
        );
        yield put(messagesActions.addMessages(messages));
      } catch (error) {
        yield put(
          actions.getConversationSuccess(conversationId, false, {
            id: conversationId,
            users,
            isNewMatch: true,
          })
        );
      }
    } else {
      const { moreRecords, messages, nextCursor } = yield call(GetMessages, id, messageLimit);
      yield put(actions.getConversationSuccess(id, moreRecords, null, nextCursor));
      yield put(messagesActions.addMessages(messages));
    }
  } catch (error) {
    yield put(actions.getConversationFailure(error));
  }
}

function* loadMore({ id, nextCursor }) {
  try {
    const { moreRecords, messages, nextCursor: nextCursorr } = yield call(
      GetMessages,
      id,
      messageLimit,
      nextCursor
    );
    yield put(actions.loadMoreConversationSuccess(id, moreRecords, nextCursorr));
    yield put(messagesActions.addMessages(messages));
  } catch (error) {
    yield put(actions.loadMoreConversationFailure(error));
  }
}

function* deleteConversation({ id }) {
  try {
    yield call(DeleteConversation, id);
    yield put(actions.deleteConversationSuccess(id));
  } catch (error) {
    yield put(actions.deleteConversationFailure(error));
  }
}

function* handleDeleteConversation() {
  yield takeLatest(types.deleteConversation, deleteConversation);
}

function* handleLoadMore() {
  yield takeLatest(types.loadMoreConversation, loadMore);
}

function* handleGetConversation() {
  yield takeLatest(types.getConversation, getConversation);
}

export default {
  handleGetConversation,
  handleLoadMore,
  handleDeleteConversation,
};
