import { combineReducers } from "redux";
import { keyBy, mergeWith, uniq } from "lodash";
import types from "./types";
import { userInformationTypes } from "../userInformation";

const giftedUserIdsReducer = (state = [], action) => {
  switch (action.type) {
    case types.getPresentsSuccess:
      return uniq(action.users.map(({ id }) => id));
    case types.getNewestGiftsSuccess:
      return uniq([...state, ...action.users.map(({ id }) => id)]);
    default:
      return state;
  }
};

const giftedUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getPresentsSuccess:
      return keyBy(action.users, "id");
    case types.getNewestGiftsSuccess:
      return { ...state, ...keyBy(action.users, "id") };
    default:
      return state;
  }
};

const sentGiftsReducer = (state = [], { type, presents }) => {
  switch (type) {
    case types.getSentPresentsSuccess:
      return presents;
    case types.getNewestSentGiftsSuccess:
      return mergeWith(
        {},
        state,
        keyBy(presents, ({ id, userId }) => `${id}-${userId}`),
        (objValue, srcValue) => {
          if (!objValue) {
            return srcValue;
          }
          if (!srcValue) {
            return objValue;
          }
          return { ...srcValue, ...objValue, count: srcValue.count + objValue.count };
        }
      );
    default:
      return state;
  }
};

const sentGiftsUsersReducer = (state = [], { type, users }) => {
  switch (type) {
    case types.getSentPresentsSuccess:
      return users;
    default:
      return state;
  }
};

const sentGiftsWithUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getSentPresentsSuccess:
      return keyBy(action.presents, ({ id, userId }) => `${id}-${userId}`);
    case types.getNewestSentGiftsSuccess:
      return mergeWith(
        {},
        state,
        keyBy(action.presents, ({ id, userId }) => `${id}-${userId}`),
        (objValue, srcValue) => {
          if (!objValue) {
            return srcValue;
          }
          if (!srcValue) {
            return objValue;
          }
          return { ...srcValue, ...objValue, count: srcValue.count + objValue.count };
        }
      );
    default:
      return state;
  }
};

const givenGiftsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.getPresentsSuccess:
      return keyBy(action.presents, ({ id, userId }) => `${id}-${userId}`);
    case types.getNewestGiftsSuccess:
      return mergeWith(
        {},
        state,
        keyBy(action.presents, ({ id, userId }) => `${id}-${userId}`),
        (objValue, srcValue) => {
          if (!objValue) {
            return srcValue;
          }
          if (!srcValue) {
            return objValue;
          }
          return { ...srcValue, ...objValue, count: srcValue.count + objValue.count };
        }
      );
    default:
      return state;
  }
};

const giftsReducer = (state = [], { type, presents }) => {
  switch (type) {
    case types.getPresentsSuccess:
      return presents;
    default:
      return state;
  }
};

const giftListReducer = (state = [], { type, list }) => {
  switch (type) {
    case types.getGiftListSuccess:
      return list;
    default:
      return state;
  }
};
const sendingReducer = (state = false, { type }) => {
  switch (type) {
    case types.sendGift:
      return true;
    case types.sendGiftSuccess:
      return false;
    default:
      return state;
  }
};

const loadingReducer = (state = false, { type }) => {
  switch (type) {
    case types.getPresents:
      return true;
    case types.getPresentsSuccess:
    case types.getPresentsFailure:
      return false;
    case types.getSentPresents:
      return true;
    case types.getSentPresentsSuccess:
    case types.getSentPresentsFailure:
      return false;
    default:
      return state;
  }
};

const lastLoadTimeReducer = (state = null, action) => {
  switch (action.type) {
    case types.getPresentsSuccess:
    case types.getNewestGiftsSuccess:
      return action.timestamp;
    default:
      return state;
  }
};

const newPresentsReducer = (state = 0, action) => {
  switch (action.type) {
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.newGifts !== undefined)
        return info.newGifts;
      return state;
    }
    case types.getPresentsSuccess:
      return 0;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching: loadingReducer,
  newPresents: newPresentsReducer,
  isSending: sendingReducer,
  giftList: giftListReducer,
  gifts: giftsReducer,
  lastLoadTimeReducer,
  users: combineReducers({ allIds: giftedUserIdsReducer, byId: giftedUsersReducer }),
  givenGifts: givenGiftsReducer,
  sentGifts: sentGiftsReducer,
  sentGiftsUsers: sentGiftsUsersReducer,
  sentGiftsWithUsersReducer
});
