const getPushNotificationPart = state => state.pushNotifications;
const getPushNotification = state => getPushNotificationPart(state).push;
const getPushRoute = state => getPushNotificationPart(state).route;
const getNotificationPermissions = state => getPushNotificationPart(state).notificationPermission;

export default {
  getPushNotification,
  getPushRoute,
  getNotificationPermissions
};
