import types from './types';

const getBlockedUser = () => ({ type: types.getBlockedUser });
const getBlockedUserSuccess = (users, moreItems, nextCursor) => ({
  type: types.getBlockedUserSuccess,
  users,
  moreItems,
  nextCursor,
});
const getBlockedUserFailure = (error) => ({ type: types.getBlockedUserFailure, error });
const loadMore = (nextCursor) => ({ type: types.loadMore, nextCursor });
const loadMoreSuccess = (users, moreItems, nextCursor) => ({
  type: types.loadMoreSuccess,
  users,
  moreItems,
  nextCursor,
});
const loadMoreFailure = (error) => ({ type: types.loadMoreFailure, error });
const unBlockUser = (userId) => ({ type: types.unBlockUser, userId });
const unBlockUserSuccess = (userId) => ({
  type: types.unBlockUserSuccess,
  userId,
});
const unBlockUserFailure = (error) => ({ type: types.unBlockUserFailure, error });

export default {
  getBlockedUser,
  getBlockedUserSuccess,
  getBlockedUserFailure,
  unBlockUser,
  unBlockUserSuccess,
  unBlockUserFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
};
