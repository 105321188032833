import { combineReducers } from 'redux';
import { assign } from 'lodash';
import types from './types';
import { userInformationTypes } from '../userInformation';

const pushReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.setPushNotification:
      return action.notification;
    default:
      return state;
  }
};

const routeReducer = (state = null, action) => {
  switch (action.type) {
    case types.setNavigation:
      return action.route;
    default:
      return state;
  }
};

const notificationPermissionsReducer = (state = {}, action) => {
  switch (action.type) {
    case types.setNotificationPermissions:
      return action.permissions;
    case userInformationTypes.updateUserInformation: {
      const { information } = action;
      if (information.permissions !== undefined)
        return information.permissions;
      return state;
    }
    case types.updatePermissionSuccess:
      return assign({}, state, action.permissions);
    default:
      return state;
  }
};

export default combineReducers({ push: pushReducer, route: routeReducer, notificationPermission: notificationPermissionsReducer });
