import types from './types';

const reducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.addNotification: {
      const { notification } = action;
      return [...state, notification];
    }
    default:
      return state;
  }
};

// export default reducer;
export default reducer;