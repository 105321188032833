import { uniq, keyBy, omit } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import { userInformationTypes } from '../userInformation';

const allIdsReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.loadSuccess: {
      const { visitors } = action;
      return uniq(visitors.map(({ id }) => id));
    }
    case types.loadMoreSuccess: {
      const { visitors } = action;
      return uniq([...state, ...visitors.map(({ id }) => id)]);
    }
    default:
      return state;
  }
};

const byIdReducer = (state = {}, action) => {
  const { type } = action;
  switch (type) {
    case types.loadSuccess: {
      const { visitors } = action;
      return keyBy(visitors, 'id');
    }
    case types.loadMoreSuccess: {
      const { visitors } = action;
      return { ...state, ...keyBy(visitors, 'id') };
    }
    case types.unlock:
    case types.unlockSuccess:
    case types.unlockFailure: {
      const { id } = action;
      return { ...state, [id]: valueReducer(state[id], action) };
    }
    default:
      return state;
  }
};

const valueReducer = (state, { type, ...actionParameter }) => {
  switch (type) {
    case types.unlockFailure: {
      const { error } = actionParameter;
      return omit({ ...state, error }, 'isLoading');
    }
    case types.unlockSuccess: {
      const { userInfo } = actionParameter;
      return omit({ ...state, ...userInfo, unlocked: true }, 'isLoading');
    }
    case types.unlock: {
      return { ...state, isLoading: true, recentlyUnlocked: true };
    }
    default:
      return state;
  }
};

const loaderReducer = (state = false, { type }) => {
  switch (type) {
    case types.load:
    case types.loadMore:
      return true;
    case types.loadMoreSuccess:
    case types.loadMoreFailure:
    case types.loadFailure:
    case types.loadSuccess:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  const { type, error } = action;
  switch (type) {
    case types.load:
    case types.loadSuccess:
      return null;
    case types.loadFailure:
      return error;
    default:
      return state;
  }
};

const lastLoadTimeReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.setLastLoadTime: {
      const { time } = action;
      return time || null;
    }
    default:
      return state;
  }
};

const newVisitorsReducer = (state = 0, action) => {
  const { type, info } = action;
  switch (type) {
    case userInformationTypes.getUserFeedSuccess: {
      if (info.newVisitors !== undefined) return info.newVisitors;
      return state;
    }
    case types.addNewVisitor: {
      return state + 1;
    }
    case types.loadSuccess: {
      return 0;
    }
    default:
      return state;
  }
};

const pageReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.loadSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    case types.loadMoreSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    default:
      return state;
  }
};

const moreItemsReducer = (state = true, action) => {
  const { type, moreItems } = action;
  switch (type) {
    case types.loadMoreSuccess:
    case types.loadSuccess:
      return moreItems;
    default:
      return state;
  }
};

export default combineReducers({
  loading: loaderReducer,
  error: errorReducer,
  collection: combineReducers({ byId: byIdReducer, allIds: allIdsReducer }),
  lastLoadTime: lastLoadTimeReducer,
  newVisitors: newVisitorsReducer,
  nextPage: pageReducer,
  moreItems: moreItemsReducer,
});
