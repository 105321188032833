import types from './types';

const getConversationFromUsers = (users) => ({ type: types.getConversation, users });
const getConversation = (id) => ({ type: types.getConversation, id });

const getConversationSuccess = (id, moreRecords, conversation, nextCursor) => ({
  type: types.getConversationSuccess,
  id,
  moreRecords,
  nextCursor,
  conversation,
});
const getConversationFailure = (error) => ({ type: types.getConversationFailure, error });
const loadMoreConversation = (id, nextCursor) => ({
  type: types.loadMoreConversation,
  id,
  nextCursor,
});
const loadMoreConversationSuccess = (id, moreRecords, nextCursor) => ({
  type: types.loadMoreConversationSuccess,
  id,
  moreRecords,
  nextCursor,
});
const loadMoreConversationFailure = (error) => ({ type: types.loadMoreConversationFailure, error });
const deleteConversation = (id) => ({ type: types.deleteConversation, id });
const deleteConversationSuccess = (id) => ({ type: types.deleteConversationSuccess, id });
const deleteConversationFailure = (error) => ({ type: types.deleteConversationFailure, error });
const resetConversations = (ids = []) => ({ type: types.resetConversations, ids });

export default {
  getConversationFromUsers,
  getConversation,
  getConversationSuccess,
  getConversationFailure,
  loadMoreConversation,
  loadMoreConversationSuccess,
  loadMoreConversationFailure,
  deleteConversation,
  deleteConversationSuccess,
  deleteConversationFailure,
  resetConversations,
};
