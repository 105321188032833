import types from './types';

const load = () => ({ type: types.load });
const loadSuccess = (loadedSwipes, swipeCount) => ({
  type: types.loadSuccess,
  loadedSwipes,
  swipeCount,
});
const loadFailure = error => ({ type: types.loadFailure, error });
const clearSwipes = () => ({ type: types.clearSwipes });
const swipeRight = id => ({ type: types.swipeRight, id });
const swipeLeft = id => ({ type: types.swipeLeft, id });
const swipeFailure = error => ({ type: types.swipeFailure, error });
const matched = conversation => ({ type: types.matched, conversation });
const matchFinished = () => ({ type: types.matchFinished });

export default {
  load,
  loadSuccess,
  loadFailure,
  swipeRight,
  swipeLeft,
  swipeFailure,
  matched,
  matchFinished,
  clearSwipes,
};
