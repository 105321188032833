import { takeLatest, call, put, takeEvery, select } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import {
  GetConversations,
  GetUnreadMessages,
  MarkAsRead,
  getConversation,
  GetStarPackages,
  buyInternalService,
  SetFavorite,
} from '../services/webAppEndPoint';
import { messagesActions, messagesTypes } from '../messages';
import selectors from './selectors';
import { paymentFunctions } from '../payment';

function* getConversations() {
  try {
    const { conversations, moreRecords, nextCursor } = yield call(GetConversations);
    const [strippedConversations, messages] = conversations.reduce(
      ([cons, mess], { lastMessage, ...conversation }) => [
        [...cons, conversation],
        lastMessage ? [...mess, { ...lastMessage, conversationId: conversation.id }] : mess,
      ],
      [[], []]
    );

    const filteredMessages = messages.filter((item) => !!item.id);
    yield put(messagesActions.addMessages(filteredMessages));
    yield put(actions.getConversationsSuccess(strippedConversations, moreRecords, nextCursor));
  } catch (error) {
    yield put(actions.fetchFailure(error));
  }
}
function* getMoreConversations({ nextCursor: nextCursorToFetch }) {
  try {
    const { conversations, moreRecords, nextCursor } = yield call(GetConversations, false, nextCursorToFetch, 10);
    const [strippedConversations, messages] = conversations.reduce(
      ([cons, mess], { lastMessage, ...conversation }) => [
        [...cons, conversation],
        lastMessage ? [...mess, { ...lastMessage, conversationId: conversation.id }] : mess,
      ],
      [[], []]
    );
    const filteredMessages = messages.filter((item) => !!item.id);
    yield put(messagesActions.addMessages(filteredMessages));
    yield put(actions.getMoreConversationsSuccess(strippedConversations, moreRecords, nextCursor));
  } catch (error) {
    yield put(actions.getMoreConversationsFailure(error));
  }
}

function* getFavoriteConversations() {
  try {
    const { conversations, moreRecords } = yield call(GetConversations, true);
    const [strippedConversations, messages] = conversations.reduce(
      ([cons, mess], { lastMessage, ...conversation }) => [
        [...cons, conversation],
        lastMessage ? [...mess, { ...lastMessage, conversationId: conversation.id }] : mess,
      ],
      [[], []]
    );
    const filteredMessages = messages.filter((item) => !!item.id);
    yield put(messagesActions.addMessages(filteredMessages));
    yield put(actions.getFavoriteConversationsSuccess(strippedConversations, moreRecords));
  } catch (error) {
    yield put(actions.getFavoriteConversationsFailure(error));
  }
}

function* handleGetFavoriteConversations() {
  yield takeLatest(types.getFavoriteConversations, getFavoriteConversations);
}

function* getMoreFavoriteConversations({ page }) {
  try {
    const { conversations, moreRecords } = yield call(GetConversations, true, page);
    const [strippedConversations, messages] = conversations.reduce(
      ([cons, mess], { lastMessage, ...conversation }) => [
        [...cons, conversation],
        lastMessage ? [...mess, { ...lastMessage, conversationId: conversation.id }] : mess,
      ],
      [[], []]
    );

    const filteredMessages = messages.filter((item) => !!item.id);
    yield put(messagesActions.addMessages(filteredMessages));
    yield put(actions.getMoreFavoritesSuccess(strippedConversations, moreRecords));
  } catch (error) {
    yield put(actions.getMoreFavoritesFailure(error));
  }
}

function* handleGetMoreFavorites() {
  yield takeLatest(types.getMoreFavorites, getMoreFavoriteConversations);
}

function* addToConversations({ message: { conversationId } }) {
  const hasConversation = selectors.hasConversation(conversationId);
  const conversationExists = yield select(hasConversation);
  if (!conversationExists) {
    try {
      const conversation = yield call(getConversation, conversationId);
      yield put(actions.getConversationSuccess(conversation));
    } catch (error) {
      yield put(actions.getConversationFailure(error));
    }
  }
}

function* checkForMissingConversation() {
  yield takeEvery(messagesTypes.receiveMessage, addToConversations);
}

function* handleGetMoreConversations() {
  yield takeLatest(types.getMoreConversations, getMoreConversations);
}

function* fetchUnread() {
  try {
    const { unread, matches } = yield call(GetUnreadMessages);
    yield put(actions.fetchUnreadSuccess(unread, matches));
  } catch (error) {
    yield put(actions.fetchFailure(error));
  }
}

function* markAsRead({ id }) {
  yield call(MarkAsRead, id);
  yield put(actions.removeUnread(id));
}

function* handleGetConversations() {
  yield takeLatest(types.getConversations, getConversations);
}

function* handleGetUnread() {
  yield takeLatest(types.getUnread, fetchUnread);
}
function* handleMarkAsRead() {
  yield takeLatest(types.markAsRead, markAsRead);
}

function* starConv({ id, userId, favorite }) {
  try {
    yield call(SetFavorite, userId, favorite);
    yield put(actions.favoriteConversationSuccess(id, favorite));
  } catch (error) {
    yield put(actions.favoriteConversationFailure(error));
  }
}

function* handleStarConv() {
  yield takeLatest(types.favoriteConversation, starConv);
}

function* fetchStarPackages() {
  try {
    const products = yield call(GetStarPackages);
    yield put(actions.getStarProductsSuccess(products));
  } catch (error) {
    yield put(actions.getStarProductsFailure(error));
  }
}

function* handleFetchStarPackages() {
  yield takeLatest(types.getStarProducts, fetchStarPackages);
}

function* buyStarPackage({ id, price, count }) {
  try {
    yield call(buyInternalService, id);
    yield put(actions.buyStarPackageSuccess(count));
    yield* paymentFunctions.finishTransaction(price);
  } catch (error) {
    yield put(actions.buyStarPackageFailure(error));
  }
}

function* handleBuyStarPackage() {
  yield takeLatest(types.buyStarPackage, buyStarPackage);
}

export default {
  handleGetConversations,
  handleGetMoreConversations,
  handleGetUnread,
  handleMarkAsRead,
  checkForMissingConversation,
  handleGetFavoriteConversations,
  handleGetMoreFavorites,
  handleStarConv,
  handleFetchStarPackages,
  handleBuyStarPackage,
};
