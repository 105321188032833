import { takeLatest, call, put } from 'redux-saga/effects';
import types from './types';
import presentsActions from './actions';
import { GetPresents, SendGift, GetGiftList, GetSentPresents } from '../services/webAppEndPoint';
import { paymentFunctions } from '../payment';
import { messagesActions } from '../messages';
import Tracking from '../tracking';

function* getPresents({ id }) {
  try {
    const { presents, users } = yield call(GetPresents, id);
    yield put(presentsActions.getPresentsSuccess(presents, users));
  } catch (error) {
    yield put(presentsActions.getPresentsFailure(error));
  }
}

function* getSentPresents() {
  try {
    const { presents, users } = yield call(GetSentPresents);
    yield put(presentsActions.getSentPresentsSuccess(presents, users));
  } catch (error) {
    yield put(presentsActions.getSentPresentsFailure(error));
  }
}

function* sendGift({ id, value, present }) {
  try {
    const giftMessage = yield call(SendGift, id, present);
    yield* paymentFunctions.finishTransaction(value);
    yield put(presentsActions.sendGiftSuccess(id, present));
    yield put(messagesActions.sendMessageSuccess(giftMessage));
    Tracking.track(`sendGift.${present.replace('gift_', '')}`, { product: present });
  } catch (error) {
    yield put(presentsActions.sendGiftFailure(error));
  }
}

function* getGiftList() {
  try {
    const list = yield call(GetGiftList);
    yield put(presentsActions.getGiftListSuccess(list));
  } catch (error) {
    yield put(presentsActions.getGiftListFailure(error));
  }
}

function* handleSendGift() {
  yield takeLatest(types.sendGift, sendGift);
}

function* handleGetGiftList() {
  yield takeLatest(types.getGiftList, getGiftList);
}

function* handleGetPresents() {
  yield takeLatest(types.getPresents, getPresents);
}

function* handleGetSentPresents() {
  yield takeLatest(types.getSentPresents, getSentPresents);
}

function* getNewest({ id, timestamp }) {
  try {
    const { presents, users } = yield call(GetPresents, id, timestamp);
    yield put(presentsActions.getNewestSuccess(presents, users));
  } catch (error) {
    yield put(presentsActions.getNewestFailure(error));
  }
}

function* getNewestSent({ id, timestamp }) {
  try {
    const { presents, users } = yield call(GetSentPresents, id, timestamp);
    yield put(presentsActions.getNewestSentSuccess(presents, users));
  } catch (error) {
    yield put(presentsActions.getNewestSentFailure(error));
  }
}

function* handleGetNewest() {
  yield takeLatest(types.getNewestGifts, getNewest);
}

function* handleGetNewestSent() {
  yield takeLatest(types.getNewestSentGifts, getNewestSent);
}

export default {
  handleGetPresents,
  handleGetGiftList,
  handleSendGift,
  handleGetNewest,
  handleGetSentPresents,
  handleGetNewestSent
};
