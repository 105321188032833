import { put, take } from 'redux-saga/effects';
import actions from './actions';
import types from './types';

export function* showGlobalAlert(statusCode) {
  yield put(actions.setAlert(true, statusCode));
  const { type } = yield take([types.acceptAlert, types.declineAlert]);
  yield put(actions.setAlert(false));
  if (type === types.declineAlert) {
    throw new Error('abort');
  }
}
