import { createSelector } from 'reselect';

const getConversationMessagesPart = state => state.conversationMessages;
const getConversationMessagesById = state => getConversationMessagesPart(state).byId;
const getIsDeleting = state => getConversationMessagesPart(state).isDeleting;

const makeGetInformationForConversation = getConversationId => createSelector(
  getConversationId,
  getConversationMessagesById,
  (id, items) => items[id] || {},
);

export default {
  makeGetInformationForConversation,
  getIsDeleting
};
