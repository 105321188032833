import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { GetSwipes, Like, Dislike } from '../services/webAppEndPoint';
import { takeUniq } from '../sagas';

function* loadSwipes() {
  try {
    const { swipes, count } = yield call(GetSwipes);
    yield put(actions.loadSuccess(swipes, count));
  } catch (error) {
    yield put(actions.loadFailure(error));
  }
}

function* like({ id }) {
  const conversation = yield call(Like, id);
  if (conversation) {
    yield put(actions.matched(conversation));
  }
}

function* dislike({ id }) {
  yield call(Dislike, id);
}

function* handleSwipeRight() {
  yield* takeUniq(types.swipeRight, like, ({id}) => id);
}

function* handleSwipeLeft() {
  yield* takeUniq(types.swipeLeft, dislike, ({id}) => id);
}

function* handleLoad() {
  yield takeLatest(types.load, loadSwipes);
}

export default {
  handleLoad,
  handleSwipeLeft,
  handleSwipeRight,
};
