import types from './types';

const deleteMessages = conversationId => ({ type: types.deleteMessages, conversationId });
const receiveMessage = message => ({ type: types.receiveMessage, message });
const sendMessage = ({
  text, conversationId, recipients, type, value, hideAlert,
}) => ({
  type: types.sendMessage,
  message: {
    text,
    conversationId,
    recipients,
    type,
  },
  value,
  hideAlert,
});

const addMessages = messages => ({ type: types.addMessages, messages });

const sendMessageSuccess = message => ({ type: types.sendMessageSuccess, message });

const sendMessageFailure = error => ({ type: types.sendMessageFailure, error });

const sendIcebreaker = (recipient, price) => ({ type: types.sendIcebreaker, recipient, price });

const sendIcebreakerSuccess = (message, userId) => ({
  type: types.sendIcebreakerSuccess,
  message,
  userId,
});

const sendIcebreakerFailure = error => ({
  type: types.sendIcebreakerFailure,
  error,
});

export default {
  deleteMessages,
  sendMessage,
  sendMessageSuccess,
  sendMessageFailure,
  receiveMessage,
  addMessages,
  sendIcebreaker,
  sendIcebreakerSuccess,
  sendIcebreakerFailure,
};
