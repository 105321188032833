import { put, call, takeLeading, takeLatest } from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import { GetBlockedUsers, UnBlockUser } from '../services/webAppEndPoint';

function* getBlockedUser() {
  try {
    const { users, moreItems, nextCursor } = yield call(GetBlockedUsers);
    yield put(actions.getBlockedUserSuccess(users, moreItems, nextCursor));
  } catch (error) {
    yield put(actions.getBlockedUserFailure(error));
  }
}

function* getMoreBlockedUsers({ nextCursor }) {
  try {
    const { users, moreItems, nextCursor: nextCursorr } = yield call(GetBlockedUsers, nextCursor);
    yield put(actions.loadMoreSuccess(users, moreItems, nextCursorr));
  } catch (error) {
    yield put(actions.loadMoreFailure(error));
  }
}

function* unblock({ userId }) {
  try {
    yield call(UnBlockUser, userId);
    yield put(actions.unBlockUserSuccess(userId));
  } catch (error) {
    yield put(actions.unBlockUserFailure(error));
  }
}

function* handleUnBlockUser() {
  yield takeLatest(types.unBlockUser, unblock);
}

function* handleLoadMore() {
  yield takeLatest(types.loadMore, getMoreBlockedUsers);
}

function* handleGetBlockedUser() {
  yield takeLeading(types.getBlockedUser, getBlockedUser);
}

export default { handleGetBlockedUser, handleLoadMore, handleUnBlockUser };
