const getProfile = 'app/profiles/GETPROFILE';
const getProfileSuccess = 'app/profiles/GETPROFILESUCCESS';
const getProfileError = 'app/profiles/GETPROFILEERROR';
const removeProfile = 'app/profiles/REMOVEPROFILE';
const reportProfile = 'app/profiles/REPORTPROFILE';
const reportProfileSuccess = 'app/profiles/REPORTPROFILESUCCESS';
const reportProfileFailure = 'app/profiles/REPORTPROFILEFAILURE';

export default {
  getProfile,
  getProfileSuccess,
  getProfileError,
  removeProfile,
  reportProfile,
  reportProfileSuccess,
  reportProfileFailure,
};
