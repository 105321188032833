const initFirebase = 'app/pushNotifications/INITFIREBASE';
const setPushNotification = 'app/pushNotifications/setPushNotification';
const setNavigation = 'app/pushNotifications/SETNAVIGATION';
const setNotificationPermissions = 'app/settings/SETNOTIFICATIONPERMISSIONS';
const updatePermission = 'app/settings/UPDATEPERMISSION';
const updatePermissionSuccess = 'app/settings/UPDATEPERMISSIONSUCCESS';
const updatePermissionFailure = 'app/settings/UPDATEPERMISSIONFAILURE';

export default {
  initFirebase,
  setPushNotification,
  setNavigation,
  setNotificationPermissions,
  updatePermission,
  updatePermissionSuccess,
  updatePermissionFailure
};
