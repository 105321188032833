const getPresents = 'app/presents/GETPRESENTS';
const getPresentsSuccess = 'app/presents/GETPRESENTSSUCCESS';
const getPresentsFailure = 'app/presents/GETPRESENTSFAILURE';
const getSentPresents = 'app/presents/GETSENTPRESENTS';
const getSentPresentsSuccess = 'app/presents/GETSENTPRESENTSSUCCESS';
const getSentPresentsFailure = 'app/presents/GETSENTPRESENTSFAILURE';
const sendGift = 'app/presents/SENDGIFT';
const sendGiftSuccess = 'app/presents/SENDGIFTSUCCSESS';
const sendGiftFailure = 'app/presents/SENDGIFTFAILURE';
const getGiftList = 'app/presents/GETGIFTLIST';
const getGiftListSuccess = 'app/presents/GETGIFTSLISTSUCCESS';
const getGiftListFailure = 'app/presents/GETGIFTSLISTFAILURE';
const getNewestGifts = 'app/presents/GETNEWEST';
const getNewestGiftsSuccess = 'app/presents/GETNEWESTSUCCESS';
const getNewestGiftsFailure = 'app/presents/GETNEWESTFAILURE';
const getNewestSentGifts = 'app/presents/GETNEWESTSENT';
const getNewestSentGiftsSuccess = 'app/presents/GETNEWESTSENTSUCCESS';
const getNewestSentGiftsFailure = 'app/presents/GETNEWESTSENTFAILURE';

export default {
  getPresents,
  getPresentsSuccess,
  getPresentsFailure,
  sendGift,
  sendGiftSuccess,
  sendGiftFailure,
  getGiftList,
  getGiftListSuccess,
  getGiftListFailure,
  getNewestGifts,
  getNewestGiftsSuccess,
  getNewestGiftsFailure,
  getSentPresents,
  getSentPresentsSuccess,
  getSentPresentsFailure,
  getNewestSentGifts,
  getNewestSentGiftsSuccess,
  getNewestSentGiftsFailure,
};
