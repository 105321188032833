import { createStructuredSelector } from 'reselect';

const getAlertPart = state => state.globalAlert;
const getHocAlert = state => getAlertPart(state).hocAlert;
const getHocStatusCode = state => getAlertPart(state).hocStatusCode;

const getHocInformation = createStructuredSelector({
  hocAlert: getHocAlert,
  hocStatusCode: getHocStatusCode,
});

export default { getHocInformation };
