import { createStructuredSelector, createSelector } from "reselect";
import { groupBy, values, sortBy, reverse } from "lodash";
import { getUnixTime, parseISO } from "date-fns";

const getPresentPart = state => state.presents;
const getGiftList = state => getPresentPart(state).giftList;
const getGifts = state => getPresentPart(state).givenGifts;
const getIsFetching = state => getPresentPart(state).isFetching;
const getNewGifts = state => getPresentPart(state).newPresents;
const getNewSentGifts = state => getPresentPart(state).newSentPresents;
const getUserPart = state => getPresentPart(state).users;
const getUserIds = state => getUserPart(state).allIds;
const getUserDictionary = state => getUserPart(state).byId;
const getLastLoadTime = state => getPresentPart(state).lastLoadTime;
const getSentGiftsUsers = state => getPresentPart(state).sentGiftsUsers;
const getSentGiftsWithUsersReducer = state => getPresentPart(state).sentGiftsWithUsersReducer;
const getIsSending = state => getPresentPart(state).isSending;

const getUserId = (_state, { navigation }) => navigation.getParam("userId");
// const getUserId = (state, { navigation }) => state.profile.data.id || navigation.getParam("userId");

const getGroupedGifts = createSelector(
  getGifts,
  gifts => groupBy(values(gifts), "userId")
);

const getGroupedSentGifts = createSelector(
  getSentGiftsWithUsersReducer,
  gifts => groupBy(values(gifts), "userId")
);

const getSentUsers = createSelector(
  getSentGiftsUsers,
  sentUsers => sentUsers
);

const getAllUsers = createSelector(
  getUserIds,
  getUserDictionary,
  (ids, items) => ids.map(id => items[id])
);

const makeGetUserPresents = () =>
  createSelector(
    getUserId,
    getUserDictionary,
    getGroupedGifts,
    (id, users, gifts) => ({ user: users[id], gifts: gifts[id] })
  );

const getAllGifts = createSelector(
  getGroupedGifts,
  getAllUsers,
  (gifts, users) =>
    reverse(sortBy(users.filter(({ id }) => !!gifts[id])), ({ lastGiftSent }) =>
      getUnixTime(parseISO(lastGiftSent) || new Date())
    ).map(user => ({ user, gifts: gifts[user.id] }))
);

const getAllSentGifts = createSelector(
  getGroupedSentGifts,
  getSentUsers,
  (gifts, users) =>
    reverse(sortBy(users.filter(({ id }) => !!gifts[id])), ({ lastGiftSent }) =>
    getUnixTime(parseISO(lastGiftSent) || new Date())
    ).map(user => ({ user, gifts: gifts[user.id] }))
);

const getSentGiftOverview = createStructuredSelector({
  sentGifts: getAllSentGifts,
  lastLoadTime: getLastLoadTime,
  isFetching: getIsFetching
});

const getGiftOverview = createStructuredSelector({
  gifts: getAllGifts,
  lastLoadTime: getLastLoadTime,
  isFetching: getIsFetching
});

export default {
  getGiftList,
  getGiftOverview,
  getNewGifts,
  getNewSentGifts,
  makeGetUserPresents,
  getSentUsers,
  getSentGiftOverview,
  getIsSending
};
