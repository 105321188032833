import types from './types';

const alertReducer = (state = { visible: false, statusCode: null }, action) => {
  const { type } = action;
  switch (type) {
    case types.setAlert: {
      const { visible, statusCode } = action;
      if (visible) {
        return {
          visible,
          statusCode,
        };
      }
      return { visible, statusCode: null };
    }
    default: {
      return state;
    }
  }
};

export default alertReducer;
