const getBlockedUser = 'app/blockedUser/GET';
const getBlockedUserSuccess = 'app/blockedUser/GETSUCCESS';
const getBlockedUserFailure = 'app/blockedUser/GETFAILURE';
const unBlockUser = 'app/blockedUser/UNBLOCK';
const unBlockUserSuccess = 'app/blockedUser/UNBLOCKSUCCESS';
const unBlockUserFailure = 'app/blockedUser/UNBLOCKFAILURE';
const loadMore = 'app/blockedUser/LOADMORE';
const loadMoreSuccess = 'app/blockedUser/LOADMORESUCCESS';
const loadMoreFailure = 'app/blockedUser/LOADMOREFAILURE';


export default {
    getBlockedUser,
    getBlockedUserSuccess,
    getBlockedUserFailure,
    unBlockUser,
    unBlockUserSuccess,
    unBlockUserFailure,
    loadMore,
    loadMoreSuccess,
    loadMoreFailure
};
