import { createSelector } from 'reselect';

const getSwipesPart = state => state.swipes;
const getSwipes = state => getSwipesPart(state).swipes;
const getSwipesCount = state => getSwipesPart(state).count;
const getIsLoading = state => getSwipesPart(state).isLoading;
const getLastMatch = state => getSwipesPart(state).lastMatch;

const getHasSwipes = createSelector(
  getSwipesCount,
  count => count > 0,
);
const getHasUsers = createSelector(
  getSwipes,
  swipes => swipes.length > 0,
);
const makeGetFirstSwipes = count => createSelector(
  getSwipes,
  swipes => swipes.slice(0, count),
);

export default {
  getIsLoading,
  makeGetFirstSwipes,
  getHasUsers,
  getHasSwipes,
  getLastMatch,
};
