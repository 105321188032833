import types from './types';

const getProfile = id => ({ type: types.getProfile, id });
const getProfileSuccess = data => ({ type: types.getProfileSuccess, profile: data });
const getProfileFailure = (id, data) => ({ type: types.getProfileError, error: data, id });
const removeProfile = id => ({ type: types.removeProfile, id });
const reportProfile = ({
  blocked,
  fake_profile,
  ad,
  wrong_gender,
  reported_guid,
  violation,
  guideline,
  freetext
}) => ({
  type: types.reportProfile,
  blocked,
  fake_profile,
  ad,
  wrong_gender,
  reported_guid,
  violation,
  guideline,
  freetext
});
const reportProfileSuccess = () => ({ type: types.reportProfileSuccess });
const reportProfileFailure = error => ({ type: types.reportProfileFailure, error });

export default {
  getProfile,
  getProfileSuccess,
  getProfileFailure,
  removeProfile,
  reportProfile,
  reportProfileSuccess,
  reportProfileFailure,
};
