import 'firebase/messaging';
import firebase from 'firebase/app';
import { eventChannel } from 'redux-saga';

function createFirebaseChannel() {
  console.log('createFirebaseChannel');
  return eventChannel(emitter => {
    const messageListener = firebase.messaging().onMessage(({ data: { message, type, data } }) =>
      emitter({
        message,
        type,
        data: data && JSON.parse(data),
        notificationType: 'message',
      })
    );

    return () => {
      messageListener();
    };
  });
}

export default createFirebaseChannel;
