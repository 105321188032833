import { createSelector } from 'reselect';
import {sortBy, reverse} from 'lodash';
import { parseISO } from 'date-fns';

const getVisitorPart = state => state.visitors;
const getNewVisitors = state => getVisitorPart(state).newVisitors;
const getCollection = state => getVisitorPart(state).collection;
const getAllVisitorIds = state => getCollection(state).allIds;
const getVisitorsById = state => getCollection(state).byId;
const getIsLoading = state => getVisitorPart(state).loading;
const getLastLoadRaw = state => getVisitorPart(state).lastLoadTime;
const getError = state => getVisitorPart(state).error;
const getNextPage = state => getVisitorPart(state).nextPage;
const getMoreItems = state => getVisitorPart(state).moreItems;

const getAllVisitors = createSelector(
  getAllVisitorIds,
  getVisitorsById,
  (ids, items) => ids.map(id => items[id])
);

const getLastLoadTime = createSelector(
  getLastLoadRaw,
  lastLoad => parseISO(lastLoad)
);

const getSortedVisitors = createSelector(
  getAllVisitors,
  items => reverse(sortBy(items, 'createdAt'))
);

const getLockedVisitors = createSelector(
  getAllVisitors,
  visitors => visitors.filter(({ unlocked, recentlyUnlocked }) => !unlocked || recentlyUnlocked)
);

const getSortedLockedVisitors = createSelector(
  getLockedVisitors,
  items => reverse(sortBy(items, 'createdAt'))
);

export default {
  getNewVisitors,
  getSortedVisitors,
  getIsLoading,
  getLastLoadTime,
  getError,
  getNextPage,
  getMoreItems,
  getSortedLockedVisitors,
};
