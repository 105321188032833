import { takeRightWhile, unionBy } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import { userInformationTypes } from '../userInformation';

const swipesReducer = (state = [], { type, ...actionParameter }) => {
  switch (type) {
    case types.swipeRight:
    case types.swipeLeft: {
      const { id } = actionParameter;
      return state.filter(({ id: i }) => i !== id);
    }
    case types.loadSuccess: {
      const { loadedSwipes } = actionParameter;
      return unionBy(
        state,
        takeRightWhile(loadedSwipes, ({ id }) => !state.some(({ id: userId }) => id === userId)),
        ({ id }) => id,
      );
    }
    case types.clearSwipes: {
      return [];
    }
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.load:
      return true;
    case types.loadFailure:
    case types.loadSuccess:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.loadFailure:
      return action.error;
    case types.loadSuccess:
      return null;
    default:
      return state;
  }
};

const swipeCountReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case types.loadSuccess:
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.swipes !== undefined)
        return info.swipes;
      return state;
    }
    case types.swipeRight:
    case types.swipeLeft:
      return state - 1;
    case types.swipeFailure:
      return state + 1;
    default:
      return state;
  }
};

const lastMatchReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.matched:
      return action.conversation;
    case types.matchFinished:
      return null;
    default:
      return state;
  }
};

export default combineReducers({
  swipes: swipesReducer,
  count: swipeCountReducer,
  error: errorReducer,
  isLoading: loadingReducer,
  lastMatch: lastMatchReducer,
});
