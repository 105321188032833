import types from './types';

const initFirebase = () => ({ type: types.initFirebase });
const setPushNotification = notification => ({ type: types.setPushNotification, notification });
const setNavigation = route => ({ type: types.setNavigation, route });
const updatePermission = permissions => ({
  type: types.updatePermission,
  permissions,
});
const updatePermissionSuccess = permissions => ({
  type: types.updatePermissionSuccess,
  permissions,
});
const updatePermissionFailure = error => ({ type: types.updatePermissionFailure, error });
const setNotificationPermissions = permissions => ({
  type: types.setNotificationPermissions,
  permissions,
});

export default {
  initFirebase,
  setPushNotification,
  setNavigation,
  updatePermission,
  updatePermissionSuccess,
  updatePermissionFailure,
  setNotificationPermissions
};
