import { combineReducers } from 'redux';
import types from './types';

const blockedUsersReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getBlockedUserSuccess: {
      const { users } = action;
      return users;
    }
    case types.loadMoreSuccess: {
      const { users } = action;
      return [...state, ...users];
    }
    case types.unBlockUserSuccess: {
      const { userId } = action;
      if (state?.length > 0) return state.filter((item) => item.id !== userId);
      return state;
    }
    default:
      return state;
  }
};

const loadingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getBlockedUser:
    case types.loadMore:
      return true;
    case types.getBlockedUserSuccess:
    case types.getBlockedUserFailure:
    case types.loadMoreSuccess:
    case types.loadMoreFailure:
      return false;
    default:
      return state;
  }
};

const pageReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.getBlockedUserSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    case types.loadMoreSuccess: {
      const { nextCursor } = action;
      return nextCursor;
    }
    default:
      return state;
  }
};

const moreItemsReducer = (state = true, action) => {
  const { type, moreItems } = action;
  switch (type) {
    case types.loadMoreSuccess:
    case types.getBlockedUserSuccess:
      return moreItems;
    default:
      return state;
  }
};

export default combineReducers({
  users: blockedUsersReducer,
  isLoading: loadingReducer,
  nextPage: pageReducer,
  moreItems: moreItemsReducer,
});
