import {
  takeEvery, call, put, takeLeading, delay
} from 'redux-saga/effects';
import types from './types';
import messagesActions from './actions';
import { SendChatmessage } from '../services/webAppEndPoint';
import { paymentFunctions } from '../payment';
import { globalAlertFunctions } from '../alert';
import conversationsAction from '../conversations/actions';

function* sendMessage({
  message: {
    text, conversationId, recipients, type,
  }, value,
}) {
  const isNewConversation = !conversationId || conversationId === "";
  try {
    const message = yield call(SendChatmessage, {
      text,
      conversationId,
      recipients,
      type,
    });
    yield* paymentFunctions.finishTransaction(value);
    yield put(messagesActions.sendMessageSuccess(message));
    if(isNewConversation){
      yield delay(1500);
      yield put(conversationsAction.getConversations());
  }
  } catch (error) {
    const { statusCode } = error;
    if (statusCode === 400) {
      yield* globalAlertFunctions.showGlobalAlert('badwords');
    }
    yield put(messagesActions.sendMessageFailure(error));
  }
}

function* handleSendMessage() {
  yield takeEvery(types.sendMessage, sendMessage);
}

function* sendIcebreaker({ recipient, price }) {
  try {
    const message = yield call(SendChatmessage, {
      text: '',
      recipients: recipient,
      type: 'icebreaker',
    });
    yield* paymentFunctions.finishTransaction(price);
    yield put(messagesActions.sendIcebreakerSuccess(message, recipient));
  } catch (error) {
    yield put(messagesActions.sendIcebreakerFailure(error));
  }
}

function* handleSendIcebreaker() {
  yield takeLeading(types.sendIcebreaker, sendIcebreaker);
}

export default { handleSendMessage, handleSendIcebreaker };
