import { createSelector } from 'reselect';

const getProfilesPart = state => state.profiles;

const getProfilesById = state => getProfilesPart(state).list;
const getIsReporting = state => getProfilesPart(state).isReporting;

const makeGetProfile = getProfileId =>
  createSelector(
    getProfilesById,
    getProfileId,
    (items, id) => items[id]
  );

export default { makeGetProfile, getIsReporting };
