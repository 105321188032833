import { put, call } from 'redux-saga/effects';
import actions from './actions';
import types from './types';
import { GetConfig } from '../services/webAppEndPoint';
import { takeTimed } from '../sagas';

function* getConfig() {
  try {
    const config = yield call(GetConfig);
    yield put(actions.getConfigurationSuccess(config));
  } catch (error) {
    yield put(actions.getConfigurationFailure(error));
  }
}

function* handleGetConfig() {
  yield takeTimed(types.getConfiguration, getConfig, 300);
}

export default { handleGetConfig };
