import { uniq, uniqBy, keyBy, omit, findKey, difference, pick, last } from 'lodash';
import { combineReducers } from 'redux';
import types from './types';
import { conversationMessagesTypes } from '../conversationMessages';
import { messagesTypes } from '../messages';
import swipesTypes from '../swipes/types';
import { blockedUserTypes } from '../blockedUser';
import { userInformationTypes } from '../userInformation';

const conversationsReducer = (state = { allIds: [], byId: {} }, action) => {
  const { allIds, byId } = state;
  switch (action.type) {
    case types.getConversationsSuccess: {
      const leftIds = allIds.filter((id) => byId[id].isFavorite);
      return {
        allIds: uniq([...leftIds, ...action.conversations.map(({ id }) => id)]),
        byId: { ...pick(byId, leftIds), ...keyBy(action.conversations, 'id') },
      };
    }
    case types.getFavoriteConversationsSuccess: {
      const leftIds = allIds.filter((id) => !byId[id].isFavorite);
      return {
        allIds: uniq([...leftIds, ...action.conversations.map(({ id }) => id)]),
        byId: { ...pick(byId, leftIds), ...keyBy(action.conversations, 'id') },
      };
    }
    case types.getNewestConversationsSuccess:
    case types.getMoreFavoritesSuccess:
    case types.getMoreConversationsSuccess: {
      const { conversations } = action;
      return {
        allIds: uniq([...allIds, ...conversations.map(({ id }) => id)]),
        byId: { ...byId, ...keyBy(conversations, 'id') },
      };
    }
    case conversationMessagesTypes.deleteConversationSuccess: {
      return { allIds: allIds.filter((item) => item !== action.id), byId: omit(byId, action.id) };
    }
    case conversationMessagesTypes.resetConversations:
    case conversationMessagesTypes.deleteConversations:
      return {
        allIds: difference(allIds, action.ids),
        byId: omit(byId, action.ids),
      };
    case swipesTypes.matched:
    case types.addConversation: {
      const { conversation } = action;
      const { id } = conversation;
      return { allIds: uniq([...allIds, id]), byId: { ...byId, [id]: conversation } };
    }
    case types.favoriteConversationSuccess:
    case conversationMessagesTypes.getConversationSuccess: {
      const { id } = action;
      return {
        allIds: uniq([...allIds, id]),
        byId: { ...byId, [id]: conversationReducer(byId[id], action) },
      };
    }
    case types.getConversationSuccess: {
      const { id, conversation } = action;
      return { allIds: uniq([...allIds, id]), byId: { ...byId, [id]: conversation } };
    }
    case messagesTypes.sendMessageSuccess: {
      const {
        message: { conversationId: id },
      } = action;
      return { allIds, byId: { ...byId, [id]: conversationReducer(byId[id], action) } };
    }
    case types.sentIcebreaker:
    case blockedUserTypes.unBlockUserSuccess: {
      const { userId } = action;
      const id = findKey(byId, ({ users }) => users.some(({ id }) => id === userId));
      if (!id) {
        return state;
      }
      return { allIds, byId: { ...byId, [id]: conversationReducer(byId[id], action) } };
    }
    default:
      return state;
  }
};

const conversationReducer = (state = {}, action) => {
  switch (action.type) {
    case conversationMessagesTypes.getConversationSuccess:
      return {
        ...state,
        ...(action.conversation || {}),
      };
    case types.sentIcebreaker: {
      return { ...state, sentIcebreaker: true };
    }
    case blockedUserTypes.unBlockUserSuccess: {
      return { ...state, canAnswer: true };
    }
    case types.favoriteConversationSuccess: {
      return { ...state, isFavorite: action.favorite };
    }
    case messagesTypes.sendMessageSuccess: {
      return { ...state, isNewMatch: false };
    }
    default:
      return state;
  }
};

const unreadReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.fetchUnreadSuccess: {
      const { unread } = action;
      return unread;
    }
    case types.removeUnread: {
      const { id } = action;
      return state.filter(({ id: itemId }) => itemId !== id);
    }
    case messagesTypes.receiveMessage: {
      const {
        message: { conversationId },
      } = action;
      if (state.some(({ id }) => conversationId === id)) {
        return state.map((unread) => ({
          ...unread,
          count: unread.id === conversationId ? unread.count + 1 : unread.count,
        }));
      }
      return [...state, { id: conversationId, count: 1 }];
    }
    default:
      return state;
  }
};

const newMatchReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.fetchUnreadSuccess: {
      const { matches } = action;
      return matches;
    }
    case messagesTypes.sendMessageSuccess:
    case messagesTypes.receiveMessage: {
      const {
        message: { conversationId },
      } = action;
      return state.filter((id) => id !== conversationId);
    }
    default:
      return state;
  }
};

const fetchReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.getConversations:
    case types.getMoreConversations:
      return true;
    case types.getConversationsSuccess:
    case types.getMoreConversationsSuccess:
    case types.fetchFailure:
      return false;
    default:
      return state;
  }
};

const errorReducer = (state = null, action) => {
  const { type } = action;
  switch (type) {
    case types.getMoreConversationsFailure:
    case types.fetchFailure:
      return action.error;
    default:
      return state;
  }
};

const nextPageReducer = (state = null, action) => {
  switch (action.type) {
    case types.getConversationsSuccess: {
      const { nextCursor } = action;
      return nextCursor || null;
    }
    case types.getMoreConversationsSuccess: {
      const { nextCursor } = action;
      return nextCursor || null;
    }
    default:
      return state;
  }
};

const moreRecordsReducer = (state = true, action) => {
  const { type, moreRecords } = action;
  switch (type) {
    case types.getMoreConversationsSuccess:
    case types.getConversationsSuccess:
      return moreRecords;
    default:
      return state;
  }
};

const favoriteNextPageReducer = (state = 1, action) => {
  switch (action.type) {
    case types.getFavoriteConversationsSuccess: {
      const { nextCursor } = action;
      return nextCursor || null;
    }
    case types.getMoreFavoritesSuccess: {
      const { nextCursor } = action;
      return nextCursor || null;
    }
    default:
      return state;
  }
};

const favoriteMoreRecordsReducer = (state = true, action) => {
  const { type, moreRecords } = action;
  switch (type) {
    case types.getMoreFavoritesSuccess:
    case types.getFavoriteConversationsSuccess:
      return moreRecords;
    default:
      return state;
  }
};

const favoriteIsLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case types.getFavoriteConversations:
    case types.getMoreConversations:
      return true;
    case types.getMoreFavoritesSuccess:
    case types.getMoreFavoritesFailure:
    case types.getFavoriteConversationsSuccess:
    case types.getFavoriteConversationsFailure:
      return false;
    default:
      return state;
  }
};

const onQuickViewReducer = (state = [], action) => {
  switch (action.type) {
    case messagesTypes.receiveMessage: {
      const {
        message: { conversationId, userId },
      } = action;
      return uniqBy([...state, { id: conversationId, isNew: true, userId }], 'id');
    }
    case types.markAsRead: {
      const { id } = action;
      return state.map((item) => (item.id === id ? { ...item, isNew: false } : item));
    }
    case types.closeQuickView:
      return state.filter((item) => item.id !== action.id);
    default:
      return state;
  }
};
const openStarsReducer = (state = 0, action) => {
  const { type } = action;
  switch (type) {
    case types.buyStarPackageSuccess:
      return state + action.count;
    case types.favoriteConversationSuccess:
      return action.favorite ? state - 1 : state + 1;
    case userInformationTypes.getUserFeedSuccess: {
      const { info } = action;
      if (info.favoriteLeft !== undefined) return info.favoriteLeft;
      return state;
    }
    default:
      return state;
  }
};

const productReducer = (state = [], action) => {
  const { type } = action;
  switch (type) {
    case types.getStarProductsSuccess:
      return action.products;
    default:
      return state;
  }
};

const buyingReducer = (state = false, action) => {
  const { type } = action;
  switch (type) {
    case types.buyStarPackage:
      return true;
    case types.buyStarPackageSuccess:
    case types.buyStarPackageFailure:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  conversations: conversationsReducer,
  error: errorReducer,
  isFetching: fetchReducer,
  unread: unreadReducer,
  newMatches: newMatchReducer,
  nextPage: nextPageReducer,
  moreRecords: moreRecordsReducer,
  favoriteNextPage: favoriteNextPageReducer,
  favoriteMoreRecords: favoriteMoreRecordsReducer,
  favoriteIsLoading: favoriteIsLoadingReducer,
  openStars: openStarsReducer,
  products: productReducer,
  isBuying: buyingReducer,
  onQuickView: onQuickViewReducer,
});
